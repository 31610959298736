import { CommonModule } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { getEnv } from '../utils';
import { Theme, ThemeService } from './theme.service';

@Component({
  selector: 'lib-theme-switch',
  standalone: true,
  imports: [CommonModule, MatIconModule, TranslateModule],
  template: `
    <button
      *ngIf="canToggleTheme"
      (click)="toggleTheme()"
      title="{{ (colorScheme == 'light' ? 'Light' : 'Dark') | translate }} {{ 'color scheme' | translate }}"
    >
      <mat-icon *ngIf="colorScheme === 'light'">light_mode</mat-icon>
      <mat-icon *ngIf="colorScheme === 'dark'">dark_mode</mat-icon>
      <span *ngIf="showLabel">
        {{ (colorScheme === 'light' ? 'Light' : 'Dark') | translate }}
        {{ 'color scheme' | translate }}
      </span>
    </button>
  `,
  styles: [
    `
      :host {
        button {
          display: flex;
          place-items: center;
          gap: 0.5rem;
          padding: 0.5rem;
          cursor: pointer;
          height: 2.5rem;
          background: transparent;
          color: inherit;
          border: 0;
          outline: 0;
        }
        .mat-icon {
          color: currentColor;
        }
      }
    `,
  ],
})
export class ThemeSwitchComponent {
  @Input() showLabel = true;
  canToggleTheme = getEnv('allowTheming');

  theme = inject(ThemeService);

  get colorScheme() {
    return this.theme.theme();
  }

  toggleTheme() {
    this.theme.setTheme(this.theme.theme() === Theme.DARK ? Theme.LIGHT : Theme.DARK);
  }
}
